(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.cookies = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  "use strict";

  document.addEventListener('DOMContentLoaded', () => {
    const body = document.body;
    const {
      dataLayer
    } = window;
    const cookieDuration = 365;
    const cookieName = 'consentValueUpdated';
    const cookieValueAccepted = JSON.stringify({
      operational_cookies: '1',
      advertising_cookies: '1',
      performance_cookies: '1'
    });
    const cookieValueRejected = JSON.stringify({
      operational_cookies: '1',
      advertising_cookies: '0',
      performance_cookies: '0'
    });
    function createCookie(name, value, days) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
      }
      document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/; SameSite=Strict`;
    }
    function acceptCookies() {
      const banner = document.getElementById('cookies-banner');
      const button = banner.querySelector('#cookies-accept');
      if (button) {
        button.addEventListener('click', () => {
          createCookie(cookieName, cookieValueAccepted, cookieDuration);
          if (dataLayer) {
            dataLayer.push({
              event: 'consentValueUpdated',
              performance_cookies: 1,
              advertising_cookies: 1,
              operational_cookies: 1
            });
          }

          // Refresh the page
          window.location.reload();
        });
      }
    }
    function rejectCookies() {
      const banner = document.getElementById('cookies-banner');
      const button = banner.querySelector('#cookies-reject');
      if (button) {
        button.addEventListener('click', () => {
          createCookie(cookieName, cookieValueRejected, cookieDuration);
          if (dataLayer) {
            dataLayer.push({
              event: 'consentValueUpdated',
              performance_cookies: 0,
              advertising_cookies: 0,
              operational_cookies: 1
            });
          }

          // Refresh the page
          window.location.reload();
        });
      }
    }
    function shakeBanner(event) {
      const banner = document.getElementById('cookies-banner');
      if (banner && !banner.contains(event.target) && body && body.classList.contains('no-consent')) {
        banner.classList.add('shake');
      }
      setTimeout(() => {
        banner.classList.remove('shake');
      }, 600);
    }
    acceptCookies();
    rejectCookies();
    document.addEventListener('click', shakeBanner);
  });
});

},{}]},{},[1])

