document.addEventListener('DOMContentLoaded', () => {
  const body = document.body;
  const { dataLayer } = window;

  const cookieDuration = 365;
  const cookieName = 'consentValueUpdated';

  const cookieValueAccepted = JSON.stringify({
    operational_cookies: '1',
    advertising_cookies: '1',
    performance_cookies: '1',
  });

  const cookieValueRejected = JSON.stringify({
    operational_cookies: '1',
    advertising_cookies: '0',
    performance_cookies: '0',
  });

  function createCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/; SameSite=Strict`;
  }

  function acceptCookies() {
    const banner = document.getElementById('cookies-banner');
    const button = banner.querySelector('#cookies-accept');
    if (button) {
      button.addEventListener('click', () => {
        createCookie(cookieName, cookieValueAccepted, cookieDuration);
        if (dataLayer) {
          dataLayer.push({
            event: 'consentValueUpdated',
            performance_cookies: 1,
            advertising_cookies: 1,
            operational_cookies: 1,
          });
        }

        // Refresh the page
        window.location.reload();
      });
    }
  }

  function rejectCookies() {
    const banner = document.getElementById('cookies-banner');
    const button = banner.querySelector('#cookies-reject');
    if (button) {
      button.addEventListener('click', () => {
        createCookie(cookieName, cookieValueRejected, cookieDuration);
        if (dataLayer) {
          dataLayer.push({
            event: 'consentValueUpdated',
            performance_cookies: 0,
            advertising_cookies: 0,
            operational_cookies: 1,
          });
        }

        // Refresh the page
        window.location.reload();
      });
    }
  }

  function shakeBanner(event) {
    const banner = document.getElementById('cookies-banner');

    if (banner && !banner.contains(event.target) && body && body.classList.contains('no-consent')) {
      banner.classList.add('shake');
    }

    setTimeout(() => {
      banner.classList.remove('shake');
    }, 600);
  }

  acceptCookies();
  rejectCookies();

  document.addEventListener('click', shakeBanner);
});
